import Mainhome from "../src/js/pages/Mainhome";

import { Routes, Route } from "react-router-dom";

import classes from "./css/styles/App.module.css";
import Development from "./js/pages/Development";
import Incubation from "./js/pages/Incubation";

import Fundraising from "./js/pages/Fundraising";

// import Contact from "./js/pages/Contact";
import Hackfest from "./js/pages/Hackfest";
import Contactus from "./js/components/Cohortzform";
import BecomeAPartner from "./js/pages/Becomeapartner";

function App() {
  return (
    // <div className={classes.App}>
    <Routes>
      <Route exact path="/" element={<Mainhome />} />
      {/* <Route index element={<Mainhome />} /> */}

      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/Hackfest" element={<Hackfest />} />

      <Route path="/Incubation" element={<Incubation />} />
      <Route path="Development" element={<Development />} />
      <Route path="Fundraising" element={<Fundraising />} />
      <Route path="Cohortzform" element={<Contactus />} />
      <Route path="Becomeapartner" element={<BecomeAPartner />} />
    </Routes>
    // </div>
  );
}

export default App;
