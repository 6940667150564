import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Outlet, Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import useScrollAnimation from "../customHooks/useScrollAnimation";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { parent, children } from "../animations/animations";
// import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
// import logo from "../../../assets/Logo.png";
import "../../css/contactus/animations.min.css";
// import "../../css/contactus/brands.min.css";
import "../../css/contactus/classic-themes.min.css";
import img1 from "../../../src/assets/trustvibeslogo.png";
import img2 from "../../../src/assets/personifilogo2.png";
// import Developmentmain from "../../../src/assets/Developmentmain.jpg";
import Fundraisings from "../../../src/assets/fundrising.png";
import icon1 from "../../../src/assets/Legal.png";
import icon2 from "../../../src/assets/Project.png";
import icon3 from "../../../src/assets/VC.png";
import icon4 from "../../../src/assets/SEED.png";

import logo from "../../../src/assets/soluslablogo.png";
import { GrFacebookOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";

import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";

import { RiYoutubeFill } from "react-icons/ri";
import { RiFacebookFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";

import { RiLinkedinBoxFill } from "react-icons/ri";

import { AiOutlineLinkedin } from "react-icons/ai";
// import videobg from "../../../src/animvid.webm";

// import "../../css/devicons.min.css";

// import "../../css/contactus/frontend-lite.min(1).css";
// import "../../css/contactus/frontend-lite.min.css";
// import ".post-9.css";
import "../../css/contactus/post-44.css";
import "../../css/contactus/post-60.css";

// import "../../css/contactus/post-7288.css";
// import "../../css/contactus/post-728.css";

// import "../../css/contactus/post-1051.css";

import "../../css/contactus/responsive.css";

// import "../../css/simple-line-icons.css";

// import "../../css/contactus/solid.min.css";

// import "../../css/contactus/style.min.css";

import "../../css/contactus/style.min(1).css";

import "../../css/contactus/swiper.min.css";

import "../../css/contactus/template-kit-export-public.min.css";

// import "../../css/contactus/theme.min.css";

// import "../../css/contactus/themify.css";

// import "../../css/contactus/contactus/widget-call-to-action.min.css";

// import "../../css/contactus/widget-carousel.min.css";

// import "../../css/contactus/widget-icon-box.min.css";

// import "../../css/contactus/widget-icon-list.min.css";

// import "../../css/contactus/widget-icon-list.min.css";

// import "../../css/contactus/widget-styles.css";
// import "../../css/contactus/widget-nav-menu.min.css";
import "../../css/contactus/post-150.css";
import classes from "../../css/Navbar.module.css";

const Contactus = ({ classname, logo }) => {
  const [show, setShow] = useState(false);
  const Links = Scroll.Link;
  const [ref, animation] = useScrollAnimation();
  const [state, handleSubmit] = useForm("xlekqddn");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <>
      <div
        data-elementor-type="header"
        data-elementor-id="44"
        class="elementor elementor-44 elementor-location-header "
      >
        <section
          class=" elementor-section elementor-top-section elementor-element elementor-element-a13ea4d elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a13ea4d"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-500 elementor-top-column elementor-element elementor-element-204272a"
              data-id="204272a"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-5b04703 elementor-section-content-middle elementor-reverse-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default "
                  data-id="5b04703"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-500 elementor-inner-column elementor-element elementor-element-ac59a29"
                      data-id="ac59a29"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-200cc96 elementor-widget elementor-widget-image"
                          data-id="200cc96"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <a href="/">
                              <img
                                title="logo_virtabox"
                                alt="logo_virtabox"
                                loading="lazy"
                                class="ls-is-cached lazyloaded"
                                src="assets/soluslabsicon7.png"
                              />
                              <noscript>
                                <img
                                  src={logo}
                                  title="logo_virtabox"
                                  alt="logo_virtabox"
                                  loading="lazy"
                                />
                              </noscript>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-500 elementor-inner-column elementor-element elementor-element-cd7c8ca"
                      data-id="cd7c8ca"
                      data-element_type="column"
                    >
                      <link
                        rel="stylesheet"
                        href="../../css/widget-nav-menu.min.css"
                      />

                      <button
                        className={
                          show
                            ? `${classes.menuBtn} ${classes.mobileMenuBtn}`
                            : classes.menuBtn
                        }
                        onClick={() => setShow(!show)}
                      >
                        {show ? <IoCloseSharp /> : <HiMenuAlt3 />}
                      </button>

                      <motion.nav
                        className={` ${classes[classname]} ${classes.navbar}`}
                        ref={ref}
                        variants={parent}
                        initial="hidden"
                        animate={animation}
                      >
                        {/* <div className={classes.logo}>
        <img src="assets/logo.png" alt="logo" />
      </div> */}

                        <motion.ul
                          ref={ref}
                          variants={parent}
                          initial="hidden"
                          animate={animation}
                          className={
                            show
                              ? `${classes.navLinks} ${classes.mobileMenu}`
                              : classes.navLinks
                          }
                        >
                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/">
            <a onClick={() => setShow(false)}>Home</a>
          </Link>
        </motion.li> */}

                          {/* <a onClick={() => setShow(false)}>EMC</a> */}
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/">Home</Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Hackfest" spy={true}>
                              {/* <a onClick={() => setShow(false)}>Services</a> */}
                              Hackfest
                            </Link>
                          </motion.li>
                          {/* <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Incubation" spy={true}>
                              Incubation
                            </Link>
                          </motion.li> */}
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Development">
                              {/* <a onClick={() => setShow(false)}>
              Products
            </a> */}
                              Development
                            </Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Fundraising">
                              {/* <a onClick={() => setShow(false)}>Portfolio</a> */}
                              Fundraising
                            </Link>
                          </motion.li>
                          <motion.li
                            // className={classes.link}
                            className={
                              show ? ` ${classes.link} ` : classes.hidee
                            }
                            variants={parent}
                          >
                            <Link to={"/Becomeapartner"} target="_blank">
                              Become A Partner
                            </Link>
                          </motion.li>
                          {/* <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Trainings">
                   
                              Trainings
                            </Link>
                          </motion.li> */}
                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/">
          <a onClick={() => setShow(false)}>
            NFTs <ToolTip />
          </a>
          </Link>
        </motion.li> */}

                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/NewsLetter">
            <a onClick={() => setShow(false)}>NewsLetter</a>
          </Link>
        </motion.li> */}
                          {/* <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/contact">
                              Get in Touch
                            </Link>
                          </motion.li> */}
                        </motion.ul>
                        {/* <button
                                className={
                                  show
                                    ? `${classes.menuBtn} ${classes.mobileMenuBtn}`
                                    : classes.menuBtn
                                }
                                onClick={() => setShow(!show)}
                              >
                                {show ? <IoCloseSharp /> : <HiMenuAlt3 />}
                              </button> */}
                      </motion.nav>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-500 elementor-top-column elementor-element elementor-element-918ac93 elementor-hidden-mobile"
              data-id="918ac93"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7684856 elementor-align-right elementor-widget elementor-widget-button"
                  data-id="7684856"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <div
                        class="elementor-button-link elementor-button elementor-size-md"
                        role="button"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-text">
                            <Link to={"/Becomeapartner"} target="_blank">
                              {" "}
                              Become A Partner
                            </Link>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="150"
        class="elementor elementor-150"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-f4fe68f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="f4fe68f"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9ee2a87"
              data-id="9ee2a87"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-39510ea elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="39510ea"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-452853c"
                      data-id="452853c"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-cf9a676 elementor-widget elementor-widget-heading animated fadeInUp"
                          data-id="cf9a676"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">
                              Cohort Z Registration
                            </h1>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-6a38edc elementor-widget elementor-widget-heading animated fadeInUp"
                          data-id="6a38edc"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp","_animation_delay":200}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default">
                              We work with cutting-edge technologies, empower
                              businesses to explore the possibilities of the Web
                              3.0 era.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-deb9db9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="deb9db9"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-014b79d"
              data-id="014b79d"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-f68d7c7 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeIn"
                  data-id="f68d7c7"
                  data-element_type="section"
                  data-settings='{"background_background":"classic","animation":"fadeIn","animation_delay":200}'
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-500 elementor-inner-column elementor-element elementor-element-ccccb15"
                      data-id="ccccb15"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-1c57b0a elementor-widget elementor-widget-heading"
                          data-id="1c57b0a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Get in touch.
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-ac74235 elementor-widget elementor-widget-heading"
                          data-id="ac74235"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            {/* <div class="elementor-heading-title elementor-size-default">
                              Sociosqu viverra lectus placerat sem efficitur
                              molestie vehicula cubilia leo etiam nam.
                            </div>{" "} */}
                          </div>
                        </div>
                        {/* <div
                          class="elementor-element elementor-element-9924d6e elementor-view-stacked elementor-shape-square elementor-position-left elementor-mobile-position-left elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="9924d6e"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-box-wrapper">
                              <div class="elementor-icon-box-icon">
                                <span class="elementor-icon elementor-animation-">
                                  <i
                                    aria-hidden="true"
                                    class="fas fa-map-marker-alt"
                                  ></i>{" "}
                                </span>
                              </div>
                              <div class="elementor-icon-box-content">
                                <div class="elementor-icon-box-title">
                                  <span>Head Office </span>
                                </div>
                                <p class="elementor-icon-box-description">
                                  Jalan Cempaka Wangi No 22 Jakarta - Indonesia{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="flexdivcontact">
                          <div
                            class="elementor-element elementor-element-a47a115 elementor-view-stacked elementor-shape-square elementor-position-left elementor-mobile-position-left elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                            data-id="a47a115"
                            data-element_type="widget"
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-">
                                    <i
                                      aria-hidden="true"
                                      class="mdi mdi-email-multiple"
                                    ></i>{" "}
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <div class="elementor-icon-box-title">
                                    <span>Email Us </span>
                                  </div>
                                  <p class="elementor-icon-box-description">
                                    John@soluslabs.xyz{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-109fa01 elementor-view-stacked elementor-shape-square elementor-position-left elementor-mobile-position-left elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                            data-id="109fa01"
                            data-element_type="widget"
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-">
                                    <i
                                      aria-hidden="true"
                                      class="mdi mdi-phone"
                                    ></i>{" "}
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <div class="elementor-icon-box-title">
                                    <span>Call Us </span>
                                  </div>
                                  <p class="elementor-icon-box-description">
                                    Phone : 92 (322) 2704644{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-f5c56a2 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                          data-id="f5c56a2"
                          data-element_type="widget"
                          data-widget_type="divider.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-divider">
                              <span class="elementor-divider-separator"></span>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          class="elementor-element elementor-element-3918353 elementor-shape-square elementor-grid-0 elementor-widget elementor-widget-social-icons"
                          data-id="3918353"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-social-icons-wrapper elementor-grid">
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-bb8f8ee"
                                  target="_blank"
                                >
                                  <span class="elementor-screen-only">
                                    Facebook-f
                                  </span>
                                  <i class="fab fa-facebook-f"></i>{" "}
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-pop elementor-repeater-item-34d0896"
                                  target="_blank"
                                >
                                  <span class="elementor-screen-only">
                                    Twitter
                                  </span>
                                  <i class="fab fa-twitter"></i>{" "}
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-309abca"
                                  target="_blank"
                                >
                                  <span class="elementor-screen-only">
                                    Linkedin-in
                                  </span>
                                  <i class="fab fa-linkedin-in"></i>{" "}
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-94b62c3"
                                  target="_blank"
                                >
                                  <span class="elementor-screen-only">
                                    Youtube
                                  </span>
                                  <i class="fab fa-youtube"></i>{" "}
                                </a>
                              </span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-deb9db9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="deb9db9"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-014b79d"
              data-id="014b79d"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-f68d7c7 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeIn"
                  data-id="f68d7c7"
                  data-element_type="section"
                  data-settings='{"background_background":"classic","animation":"fadeIn","animation_delay":200}'
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-500 elementor-inner-column elementor-element elementor-element-554200b"
                      data-id="554200b"
                      data-element_type="column"
                      data-settings='{"background_background":"gradient"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-67e498e elementor-widget elementor-widget-heading"
                          data-id="67e498e"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Send us a message
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-219ed3b elementor-widget elementor-widget-heading"
                          data-id="219ed3b"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            {/* <div class="elementor-heading-title elementor-size-default">
                              Sociosqu viverra lectus placerat sem efficitur
                              molestie vehicula cubilia leo etiam nam.
                            </div>{" "} */}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-1c384a4 elementor-widget elementor-widget-form"
                          data-id="1c384a4"
                          data-element_type="widget"
                          data-settings='{"step_next_label":"Next","step_previous_label":"Previous","button_width":"100","step_type":"number_text","step_icon_shape":"circle"}'
                          data-widget_type="form.default"
                        >
                          <div class="elementor-widget-container">
                            <form
                              class="elementor-form"
                              method="post"
                              name="New Form"
                              onSubmit={handleSubmit}
                            >
                              <input type="hidden" name="post_id" value="150" />
                              <input
                                type="hidden"
                                name="form_id"
                                value="1c384a4"
                              />
                              <input
                                type="hidden"
                                name="referer_title"
                                value="Contact us"
                              />

                              <input
                                type="hidden"
                                name="queried_id"
                                value="150"
                              />

                              <div class="elementor-form-fields-wrapper elementor-labels-above">
                                <div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-500">
                                  <label
                                    htmlFor="name"
                                    for="form-field-name"
                                    class="elementor-field-label"
                                  >
                                    Name{" "}
                                  </label>
                                  <input
                                    id="name"
                                    type="text"
                                    name="Name"
                                    size="1"
                                    class="elementor-field elementor-size-md  elementor-field-textual"
                                    placeholder="Name"
                                  />
                                  <ValidationError
                                    prefix="Email"
                                    field="email"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-field_577a511 elementor-col-500">
                                  <label
                                    for="form-field-field_577a511"
                                    class="elementor-field-label"
                                  >
                                    Contact No{" "}
                                  </label>
                                  <input
                                    id="contactno"
                                    type="PhoneNumber"
                                    name="Number"
                                    size="11"
                                    class="elementor-field elementor-size-md  elementor-field-textual"
                                    placeholder="Phone"
                                  />
                                  <ValidationError
                                    prefix="Number"
                                    field="number"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-tel elementor-field-group elementor-column elementor-field-group-email elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-email"
                                    class="elementor-field-label"
                                  >
                                    Email{" "}
                                  </label>
                                  <input
                                    id="email"
                                    type="email"
                                    name="Email"
                                    size="1"
                                    class="elementor-field elementor-size-md  elementor-field-textual"
                                    placeholder="Email Address"
                                    required="required"
                                    aria-required="true"
                                    pattern="[0-9()#&amp;+*-=.]+"
                                    title="Only numbers and phone characters (#, -, *, etc) are accepted."
                                  />
                                  <ValidationError
                                    prefix="Email"
                                    field="email"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_aa7ce35 elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-field_aa7ce35"
                                    class="elementor-field-label"
                                  >
                                    Name Of StartUp/Organization{" "}
                                  </label>
                                  <input
                                    id="nameofstartup"
                                    type="text"
                                    name="NameOfStartup"
                                    size="1"
                                    class="elementor-field elementor-size-md  elementor-field-textual"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Nmae"
                                    field="text"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_aa7ce35 elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-field_aa7ce35"
                                    class="elementor-field-label"
                                  >
                                    How many cofounders or partners does your
                                    Startup / Organization have?{" "}
                                  </label>
                                  <input
                                    id="Cofounders"
                                    type="Number"
                                    name="Cofounders"
                                    size="2"
                                    class="elementor-field elementor-size-md  elementor-field-textual"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Number"
                                    field="number"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_aa7ce35 elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-field_aa7ce35"
                                    class="elementor-field-label"
                                  >
                                    Category of your Startup / Organization{" "}
                                  </label>
                                  <textarea
                                    id="category"
                                    name="CategoryOfStartup"
                                    class="elementor-field-textual elementor-field  elementor-size-md"
                                    rows="1"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_aa7ce35 elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-field_aa7ce35"
                                    class="elementor-field-label"
                                  >
                                    Brief about the Startup / Organization{" "}
                                  </label>
                                  <textarea
                                    id="briefintro"
                                    name="BriefIntroOfStartup"
                                    class="elementor-field-textual elementor-field  elementor-size-md"
                                    rows="3"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_aa7ce35 elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-field_aa7ce35"
                                    class="elementor-field-label"
                                  >
                                    What problem in your Startup / Organization
                                    solving{" "}
                                  </label>
                                  <textarea
                                    id="probleminstartup"
                                    name="ProblemInStartup"
                                    class="elementor-field-textual elementor-field  elementor-size-md"
                                    rows="3"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_aa7ce35 elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-field_aa7ce35"
                                    class="elementor-field-label"
                                  >
                                    Names of global / local competitors{" "}
                                  </label>
                                  <textarea
                                    id="competitorname"
                                    name="GlobalCompetitorsNames"
                                    class="elementor-field-textual elementor-field  elementor-size-md"
                                    rows="3"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_aa7ce35 elementor-col-500 elementor-field-required">
                                  <label
                                    for="form-field-field_aa7ce35"
                                    class="elementor-field-label"
                                  >
                                    What is the stage of your Startup /
                                    Organization{" "}
                                  </label>
                                  <textarea
                                    id="stageofstartup"
                                    name="StageOfStartup"
                                    class="elementor-field-textual elementor-field  elementor-size-md"
                                    rows="3"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                  />
                                </div>

                                <div class="elementor-field-type-textarea elementor-field-group elementor-column elementor-field-group-message elementor-col-100">
                                  <label
                                    for="form-field-message"
                                    class="elementor-field-label"
                                  >
                                    Web/Social Media links (Website, Twitter,
                                    Telegram, Instagram, Facebook, etc.){" "}
                                  </label>
                                  <textarea
                                    id="weblinks"
                                    name="WebSocialLinks"
                                    class="elementor-field-textual elementor-field  elementor-size-md"
                                    rows="4"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                  />
                                </div>

                                <div class="elementor-field-type-textarea elementor-field-group elementor-column elementor-field-group-message elementor-col-100">
                                  <label
                                    for="form-field-message"
                                    class="elementor-field-label"
                                  >
                                    Share any achievements so far in the
                                    journey!{" "}
                                  </label>
                                  <textarea
                                    id="achievements"
                                    name="Achievements"
                                    class="elementor-field-textual elementor-field  elementor-size-md"
                                    rows="4"
                                    placeholder="Your Answer"
                                  />
                                  <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                  />
                                </div>
                                <div class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons">
                                  <button
                                    type="submit"
                                    disabled={state.submitting}
                                    class="elementor-button elementor-size-md"
                                  >
                                    <span>
                                      <span class="elementor-align-icon-left elementor-button-icon">
                                        <i
                                          aria-hidden="true"
                                          class="mdi mdi-email-multiple"
                                        ></i>{" "}
                                      </span>
                                      <span class="elementor-button-text">
                                        Submit
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>

        <div
          data-elementor-type="footer"
          data-elementor-id="60"
          class="elementor elementor-60 elementor-location-footer"
        >
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-587918e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="587918e"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-background-overlay"></div>
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8b12d21"
                data-id="8b12d21"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-d9c7592 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="d9c7592"
                    data-element_type="section"
                    data-settings='{"background_background":"gradient"}'
                  >
                    <div class="elementor-background-overlay"></div>
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2a0708d"
                        data-id="2a0708d"
                        data-element_type="column"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-47f39e0 elementor-widget elementor-widget-heading"
                            data-id="47f39e0"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-heading-title elementor-size-default">
                                SUBSCRIBE TO OUR NEWSLETTER
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-359ff19 elementor-widget elementor-widget-text-editor"
                            data-id="359ff19"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div class="elementor-widget-container">
                              <p>
                                Subscribe to our exclusive newsletter for latest
                                happenings in the tech space
                              </p>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-d8aaf94 elementor-align-center elementor-widget elementor-widget-button"
                            data-id="d8aaf94"
                            data-element_type="widget"
                            data-widget_type="button.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-button-wrapper">
                                <div class="formcontainer">
                                  <motion.input
                                    variants={children}
                                    type="text"
                                    name="user_name"
                                    placeholder="    Full Name"
                                  />

                                  <motion.input
                                    variants={children}
                                    type="email"
                                    name="user_email"
                                    placeholder="   Email Adress"
                                  />
                                  <a
                                    href="/"
                                    class="elementor-button-link elementor-button elementor-size-md"
                                    role="button"
                                  >
                                    <span class="elementor-button-content-wrapper">
                                      <span class="elementor-button-text">
                                        Subscribe
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-69616ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="69616ea"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-500 elementor-inner-column elementor-element elementor-element-cd6eece"
                        data-id="cd6eece"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-3f267bb elementor-widget elementor-widget-image"
                            data-id="3f267bb"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div class="elementor-widget-container">
                              <img
                                title="logo_virtabox"
                                alt="logo_virtabox"
                                loading="lazy"
                                data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                                class="ls-is-cached lazyloaded footerlogo"
                                src="assets/soluslabsicon7.png"
                              />
                              <noscript>
                                <img
                                  src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                                  title="logo_virtabox"
                                  alt="logo_virtabox"
                                  loading="lazy"
                                />
                              </noscript>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-column elementor-col-500 elementor-inner-column elementor-element elementor-element-5fe5f3f"
                        data-id="5fe5f3f"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-01d7379 elementor-icon-list--layout-inline elementor-align-right elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                            data-id="01d7379"
                            data-element_type="widget"
                            data-widget_type="icon-list.default"
                          >
                            <div class="elementor-widget-container">
                              <link
                                rel="stylesheet"
                                href="./Homepage – VirtaBox_files/widget-icon-list.min.css"
                              />
                              <ul class="elementor-icon-list-items elementor-inline-items">
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/">
                                    <span class="elementor-icon-list-text">
                                      Home
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Incubation">
                                    <span class="elementor-icon-list-text">
                                      Incubation
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Development">
                                    <span class="elementor-icon-list-text">
                                      Development
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Fundraising">
                                    <span class="elementor-icon-list-text">
                                      Fundraising
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to={"/Becomeapartner"} target="_blank">
                                    <span class="elementor-icon-list-text">
                                      Become A Partner
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-318e9e4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default "
                    data-id="318e9e4"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default ">
                      {/* <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-033e570 "
                        data-id="033e570"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-2c656dd elementor-shape-square e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons iconsflex"
                            data-id="2c656dd"
                            data-element_type="widget"
                            data-widget_type="social-icons.default"
                          >
                            <div class="elementor-widget-container ">
                              <div class="  iconsflex">
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-60b958b"
                                    target="_blank"
                                  >
                                    <RiFacebookFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-pop elementor-repeater-item-f4ed697"
                                    target="_blank"
                                  >
                                    <RiInstagramFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-88f8c00"
                                    target="_blank"
                                  >
                                    <RiLinkedinBoxFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-b77bf84"
                                    target="_blank"
                                  >
                                    <RiYoutubeFill />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div
                        class="elementor-column elementor-col-500 elementor-inner-column elementor-element elementor-element-a511ce2"
                        data-id="a511ce2"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-fb49ffc elementor-widget elementor-widget-heading"
                            data-id="fb49ffc"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-heading-title elementor-size-default">
                                Copyright © 2023 SolusLabs, All rights reserved.
                                Powered by SolusLabs.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Contactus;
