import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Document, Page } from "react-pdf";
import * as Scroll from "react-scroll";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import useScrollAnimation from "../customHooks/useScrollAnimation";
import { motion } from "framer-motion";
import { parent, children } from "../animations/animations";
// import logo from "../../../assets/Logo.png";
import "../../css/animations.min.css";
import "../../css/brands.min.css";
import "../../css/classic-themes.min.css";
import img1 from "../../../src/assets/GG.png";
import img2 from "../../../src/assets/3game.png";
import img3 from "../../../src/assets/NIC.png";
import img4 from "../../../src/assets/Finnex.png";
import img5 from "../../../src/assets/mparsec.png";
import img6 from "../../../src/assets/epaging.png";
// import img7 from "../../../src/assets/dappomatics.png";
import pdf from "../../../src/Document/Soluslabs_PitchDeck.pdf";

import img8 from "../../../src/assets/neon color.png";
import img9 from "../../../src/assets/apes planet.png";
import img10 from "../../../src/assets/ordeme.png";
import img11 from "../../../src/assets/wagmibiglogo.png";

import headerimg from "../../../src/assets/header.png";
import Development from "../../../src/assets/development.png";
import Incubation from "../../../src/assets/incubation.png";
import Fundraising from "../../../src/assets/fundrising.png";
import metaverses from "../../../src/assets/metaverses.png";
import gaming from "../../../src/assets/gaming.png";
import Ai from "../../../src/assets/Ai.png";

import logo from "../../../src/assets/soluslablogo.png";
import { GrFacebookOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";

import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";

import { RiFacebookFill, RiInstagramFill, RiYoutubeFill } from "react-icons/ri";
import { RiFacebookBoxFill } from "react-icons/ri";
import { RiLinkedinBoxFill } from "react-icons/ri";

import { AiOutlineLinkedin } from "react-icons/ai";
import Teams from "./Teams";
import classes from "../../css/Navbar.module.css";

//import slider from "../../../src/assets"//

import img40 from "../../../src/assets/GG.png"
import img41 from "../../../src/assets/3game.png"
import img42 from "../../../src/assets/NIC.png"
import img43 from "../../../src/assets/Finnex.png"
import img44 from "../../../src/assets/mparsec.png"
import img45 from "../../../src/assets/epaging.png"
import img46 from "../../../src/assets/neon color.png"
import img47 from "../../../src/assets/apes planet.png"
import img48 from "../../../src/assets/ordeme.png"
import img49 from "../../../src/assets/powerXfund-logo.png"
import img50 from "../../../src/assets/almlogofinal.png"
import img51 from "../../../src/assets/Crazy-battle.png"
import img52 from "../../../src/assets/gempad.png"
import img53 from "../../../src/assets/ordeme.png"
import img54 from "../../../src/assets/kommunitas-logo.png"
import img55 from "../../../src/assets/kunji-finance.png"
import img56 from "../../../src/assets/mata.png"
import img57 from "../../../src/assets/retail.png"
import img58 from "../../../src/assets/decentrAI-logo.png"

//import slider from "../../../src/assets"//



// import videobg from "../../../src/animvid.webm";

// import "../../css/devicons.min.css";

// import "../../css/ekiticons.css";

// import "../../css/elegant.css";

// import "../../css/elementor-icons.min.css";

// import "../../css/elusive-icons.min.css";

// import "../../css/fontawesome.min.css";

// import "../../css/team1.css";
// import "../../css/team2.css";

// import "../../css/team3.css";

// import "../../css/team5.css";

// import "../../css/team7.css";

// import "../../css/team11.css";

// import "../../css/team12.css";

// import "../../css/team13.css";

// import "../../css/team15.css";

// import "../../css/team16.css";

// import "../../css/team17.css";
// import "../../css/team18.css";

// import "../../css/team19.css";

// import "../../css/team21.css";

// import "../../css/icofont.min.css";

// import "../../css/icomoon_brands.css";

// import "../../css/icomoon.css";

// import "../../css/iconic.css";

// import "../../css/linearicons.css";

// import "../../css/ionicons.min.css";

// import "../../css/line-awesome.min.css";
// import "../../css/lineicons.css";

// import "../../css/materialdesignicons.min.css";
// import "../../css/open-iconic.css";
import "../../css/frontend-lite.min(1).css";
import "../../css/frontend-lite.min.css";
import "../../css/post-7.css";
import "../../css/post-44.css";
import "../../css/post-60.css";
// import "../../css/post-77.css";
// import "../../css/post-444.css";
// import "../../css/post-600.css";
import "../../css/post-7288.css";
import "../../css/post-728.css";

import "../../css/post-1051.css";

import "../../css/responsive.css";

// import "../../css/simple-line-icons.css";

import "../../css/solid.min.css";

import "../../css/style.min.css";

import "../../css/style.min(1).css";

import "../../css/swiper.min.css";

import "../../css/template-kit-export-public.min.css";

import "../../css/theme.min.css";

import "../../css/themify.css";

import "../../css/widget-call-to-action.min.css";

import "../../css/widget-carousel.min.css";

import "../../css/widget-icon-box.min.css";

import "../../css/widget-icon-list.min.css";

import "../../css/widget-icon-list.min.css";

import "../../css/widget-styles.css";
import "../../css/widget-nav-menu.min.css";
// import Popup from "./popup";
// import List from "./popup";

// import "../../css/teamsec.css";

// import "../../../"

const Mainhome = ({ classname, logo }) => {
  const [show, setShow] = useState(false);
  const Links = Scroll.Link;
  const [ref, animation] = useScrollAnimation();

  return (
    <>
    {/* <List/> */}
      <div
        data-elementor-type="header"
        data-elementor-id="44"
        class="elementor elementor-44 elementor-location-header "
      >
        <section
          class=" elementor-section elementor-top-section elementor-element elementor-element-a13ea4d elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a13ea4d"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-204272a"
              data-id="204272a"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-5b04703 elementor-section-content-middle elementor-reverse-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default "
                  data-id="5b04703"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac59a29"
                      data-id="ac59a29"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-200cc96 elementor-widget elementor-widget-image"
                          data-id="200cc96"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <a href="/">
                              <img
                                title="logo_virtabox"
                                alt="logo_virtabox"
                                loading="lazy"
                                class="ls-is-cached lazyloaded"
                                src="assets/soluslabsicon7.png"
                              />
                              <noscript>
                                <img
                                  src={logo}
                                  title="logo_virtabox"
                                  alt="logo_virtabox"
                                  loading="lazy"
                                />
                              </noscript>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cd7c8ca"
                      data-id="cd7c8ca"
                      data-element_type="column"
                    >
                      <link
                        rel="stylesheet"
                        href="../../css/widget-nav-menu.min.css"
                      />

                      <button
                        className={
                          show
                            ? `${classes.menuBtn} ${classes.mobileMenuBtn}`
                            : classes.menuBtn
                        }
                        onClick={() => setShow(!show)}
                      >
                        {show ? <IoCloseSharp /> : <HiMenuAlt3 />}
                      </button>

                      <motion.nav
                        className={` ${classes[classname]} ${classes.navbar}`}
                        ref={ref}
                        variants={parent}
                        initial="hidden"
                        animate={animation}
                      >
                        {/* <div className={classes.logo}>
        <img src="assets/logo.png" alt="logo" />
      </div> */}

                        <motion.ul
                          ref={ref}
                          variants={parent}
                          initial="hidden"
                          animate={animation}
                          className={
                            show
                              ? `${classes.navLinks} ${classes.mobileMenu}`
                              : classes.navLinks
                          }
                        >
                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/">
            <a onClick={() => setShow(false)}>Home</a>
          </Link>
        </motion.li> */}

                          {/* <a onClick={() => setShow(false)}>EMC</a> */}
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/">Home</Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Hackfest" spy={true}>
                              {/* <a onClick={() => setShow(false)}>Services</a> */}
                              Hackfest
                            </Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Incubation" spy={true}>
                              {/* <a onClick={() => setShow(false)}>Services</a> */}
                              Incubation
                            </Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Development">
                              {/* <a onClick={() => setShow(false)}>
              Products
            </a> */}
                              Development
                            </Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Fundraising">
                              {/* <a onClick={() => setShow(false)}>Portfolio</a> */}
                              Fundraising
                            </Link>
                          </motion.li>
                          <motion.li
                            // className={classes.link}
                            className={
                              show ? ` ${classes.link} ` : classes.hidee
                            }
                            variants={parent}
                          >
                            <Link to="/Becomeapartner" target="_blank">
                              Become A Partner
                            </Link>
                          </motion.li>
                          {/* <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Trainings">
                   
                              Trainings
                            </Link>
                          </motion.li> */}
                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/">
          <a onClick={() => setShow(false)}>
            NFTs <ToolTip />
          </a>
          </Link>
        </motion.li> */}

                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/NewsLetter">
            <a onClick={() => setShow(false)}>NewsLetter</a>
          </Link>
        </motion.li> */}
                          {/* <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/contact">
                              Get in Touch
                            </Link>
                          </motion.li> */}
                        </motion.ul>
                        {/* <button
                                className={
                                  show
                                    ? `${classes.menuBtn} ${classes.mobileMenuBtn}`
                                    : classes.menuBtn
                                }
                                onClick={() => setShow(!show)}
                              >
                                {show ? <IoCloseSharp /> : <HiMenuAlt3 />}
                              </button> */}
                      </motion.nav>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-918ac93 elementor-hidden-mobile"
              data-id="918ac93"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7684856 elementor-align-right elementor-widget elementor-widget-button"
                  data-id="7684856"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <div
                        class="elementor-button-link elementor-button elementor-size-md"
                        role="button"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-text">
                            <Link to="/Becomeapartner" target="_blank">
                              Become A Partner
                            </Link>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="1051"
        class="elementor elementor-1051"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-31e0b1e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="31e0b1e"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7bc2efc"
              data-id="7bc2efc"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-section elementor-inner-section elementor-element elementor-element-983483a elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="983483a"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-03b243c"
                      data-id="03b243c"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-ee810ae elementor-widget elementor-widget-heading animated fadeInUp"
                          data-id="ee810ae"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">
                              ENDLESS POSSIBILITIES
                            </h1>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-0cef25c elementor-widget elementor-widget-heading animated fadeInUp"
                          data-id="0cef25c"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp","_animation_delay":200}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              Solus Labs is a Tech Incubator specializes in web3
                              project incubation, fundraising and development.
                            </h4>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-c26012f elementor-tablet-align-center elementor-widget elementor-widget-button animated fadeInLeft"
                          data-id="c26012f"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInLeft"}'
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a
                                // href={pdf}
                                href="https://docs.google.com/presentation/d/1Ej7xRLjI3LzjsEzvQV8FqVWSh4dNeZ_tmVHJKtwLDTU/edit#slide=id.p1"
                                class="elementor-button-link elementor-button elementor-size-lg"
                                role="button"
                                target="_blank"
                                // onClick={() => window.open(pdf)}
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-text">
                                    Learn
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-87fba0c"
                      data-id="87fba0c"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-3361c39 elementor-widget elementor-widget-image animated fadeInUp"
                          data-id="3361c39"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container animationbackground">
                            {/* <div class="elementor-widget-container "> */}
                            {/* <video
                              // src={videobg}
                              src={videobg}
                              autoPlay
                              loop
                              muted
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            /> */}

                            <img
                              decoding="async"
                              width="708"
                              height="500"
                              alt=""
                              data-sizes="(max-width: 708px) 100vw, 708px"
                              class="attachment-full size-full wp-image-1053 ls-is-cached lazyloaded"
                              src={headerimg}
                              sizes="(max-width: 708px) 100vw, 708px"
                            />
                            <noscript>
                              <img
                                decoding="async"
                                width="708"
                                height="500"
                                src={headerimg}
                                class="attachment-full size-full wp-image-1053"
                                alt=""
                                srcset="
                                https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/homepage_img.png         708w,
                                https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/homepage_img-300x212.png 300w
                              "
                                sizes="(max-width: 708px) 100vw, 708px"
                              />
                            </noscript>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-288c011 elementor-absolute elementor-hidden-mobile elementor-align-center elementor-widget elementor-widget-lottie"
                          data-id="288c011"
                          data-element_type="widget"
                          data-settings='{"source":"external_url","source_external_url":{"url":"https:\/\/assets8.lottiefiles.com\/packages\/lf20_k5dcqzxm.json","is_external":"","nofollow":"","custom_attributes":""},"loop":"yes","play_speed":{"unit":"px","size":0.299999999999999988897769753748434595763683319091796875,"sizes":[]},"reverse_animation":"yes","lazyload":"yes","_position":"absolute","link_to":"none","trigger":"arriving_to_viewport","viewport":{"unit":"%","size":"","sizes":{"start":0,"end":100}},"start_point":{"unit":"%","size":"0","sizes":[]},"end_point":{"unit":"%","size":"100","sizes":[]},"renderer":"svg"}'
                          data-widget_type="lottie.default"
                        >
                          <div class="elementor-widget-container">
                            <style
                              dangerouslySetInnerHTML={{
                                __html:
                                  "\n                            .e-lottie__container {\n                              display: inline-block;\n                              max-width: var(--lottie-container-max-width);\n                              width: var(--lottie-container-width);\n                              opacity: var(--lottie-container-opacity);\n                            }\n                            .e-lottie__container:hover {\n                              opacity: var(--lottie-container-opacity-hover);\n                              transition-duration: var(\n                                --lottie-container-transition-duration-hover\n                              );\n                            }\n                            .e-lottie__container svg,\n                            .e-lottie__container svg * {\n                              transition: none !important;\n                            }\n                            .e-lottie__caption {\n                              color: var(--caption-color);\n                              margin-top: var(--caption-margin-top);\n                              text-align: var(--caption-text-align);\n                            }\n                          ",
                              }}
                            />

                            <p class="e-lottie__caption"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        




      <div className="intro-wrapper">
         <div className="text-box">
            <h3>INTRODUCING</h3>
            <a href="https://powerx.fund/" target="_blank" rel="noopener noreferrer"><img src={img49} alt="poverx" loading="lazy" /></a>
         </div>

         <div className="box-para">
          <p>REVOLUTIONIZING VENTURE CAPITAL ACCESS & UNLOCKING VC OPPORTUNITIES FOR ALL.</p>
         </div>

         <div className="color-overlay"></div>
      </div>




        <section
          class="elementor-section elementor-inner-section elementor-element elementor-element-79078cb elementor-section-boxed elementor-section-height-default elementor-section-height-default teamheadings"
          data-id="79078cb"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-befa751 animated fadeInUp"
              data-id="befa751"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-8768995 elementor-widget elementor-widget-heading"
                  data-id="8768995"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    {/* <h6 class="elementor-heading-title elementor-size-default">
                      Meet our team
                    </h6>{" "} */}
                  </div>
                </div>
                <motion.div
                  class="elementor-element elementor-element-c47412f elementor-widget elementor-widget-heading"
                  data-id="c47412f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                  variants={parent}
                  animate={animation}
                >
                  <motion.div
                    class="elementor-widget-container"
                    variants={parent}
                    animate={animation}
                  >
                    <motion.h2 class="elementor-heading-title elementor-size-default">
                      WHAT WE OFFER
                    </motion.h2>{" "}
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e596fec elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeIn"
          data-id="e596fec"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn","animation_delay":200}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-04ee3bf"
              data-id="04ee3bf"
              data-element_type="column"
              data-settings='{"background_background":"classic"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-fdf4483 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fdf4483"
                  data-element_type="section"
                ></section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-f85cc8f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="f85cc8f"
                  data-element_type="section"
                >
                  <div class="elementor-container">
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-085620d"
                      data-id="085620d"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-8809edb elementor-cta--skin-cover elementor-cta--valign-middle e-transform elementor-widget elementor-widget-call-to-action"
                          data-id="8809edb"
                          data-element_type="widget"
                          data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1999999999999999555910790149937383830547332763671875,"sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                          data-widget_type="call-to-action.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-cta">
                              <div class="elementor-cta__bg-wrapper">
                                <div
                                  class="elementor-cta__bg elementor-bg"
                                  // style="background-image: url()"
                                ></div>
                                <div class="elementor-cta__bg-overlay"></div>
                              </div>
                              <div class="elementor-cta__content">
                                <div class="elementor-content-item elementor-cta__content-item elementor-cta__image">
                                  <img
                                    decoding="async"
                                    width="512"
                                    height="512"
                                    alt=""
                                    data-sizes="(max-width: 512px) 100vw, 512px"
                                    class="attachment-full size-full wp-image-619 ls-is-cached lazyloaded"
                                    src={Incubation}
                                    sizes="(max-width: 512px) 100vw, 512px"
                                  />
                                  <noscript>
                                    <img
                                      decoding="async"
                                      width="512"
                                      height="512"
                                      src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-14img_.png"
                                      class="attachment-full size-full wp-image-619"
                                      alt=""
                                      sizes="(max-width: 512px) 100vw, 512px"
                                    />
                                  </noscript>
                                </div>

                                <div class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                                  Incubation
                                </div>
                                <div class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                                  {/* <ul>
                                    <li>- Fellowship Program</li>
                                    <li>- Mentoring/Training</li>

                                    <li>- Growth Hacking</li>
                                    <li>- PR/Networking</li>
                                    <li>- Global Exposure</li>
                                  </ul> */}
                                </div>

                                <div class="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item">
                                  <Link
                                    class="elementor-cta__button elementor-button elementor-size-xs"
                                    to="/Incubation"
                                  >
                                    Learn more
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-39c82f6"
                      data-id="39c82f6"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-591673d elementor-cta--skin-cover elementor-cta--valign-middle e-transform elementor-widget elementor-widget-call-to-action"
                          data-id="591673d"
                          data-element_type="widget"
                          data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1999999999999999555910790149937383830547332763671875,"sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                          data-widget_type="call-to-action.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-cta">
                              <div class="elementor-cta__bg-wrapper">
                                <div
                                  class="elementor-cta__bg elementor-bg"
                                  // style="background-image: url()"
                                ></div>
                                <div class="elementor-cta__bg-overlay"></div>
                              </div>
                              <div class="elementor-cta__content">
                                <div class="elementor-content-item elementor-cta__content-item elementor-cta__image">
                                  <img
                                    decoding="async"
                                    width="512"
                                    height="512"
                                    alt=""
                                    data-sizes="(max-width: 512px) 100vw, 512px"
                                    class="attachment-full size-full wp-image-618 ls-is-cached lazyloaded"
                                    src={Development}
                                    sizes="(max-width: 512px) 100vw, 512px"
                                  />
                                  {/* <noscript>
                                    <img
                                      decoding="async"
                                      width="512"
                                      height="512"
                                      src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png"
                                      class="attachment-full size-full wp-image-618"
                                      alt=""
                                      srcset="
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png         512w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-300x300.png 300w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-150x150.png 150w
                                    "
                                      sizes="(max-width: 512px) 100vw, 512px"
                                    />
                                  </noscript> */}
                                </div>

                                <div class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                                  Development
                                </div>
                                <div class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                                  {/* <ul>
                                    <li>- Metaverse Development</li>
                                    <li>- Digital Assets Market</li>

                                    <li>- Web3 Applications</li>
                                    <li>- Dapps</li>
                                    <li>- Blockchain Development</li>
                                  </ul> */}
                                </div>

                                <div class="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item">
                                  <Link
                                    class="elementor-cta__button elementor-button elementor-size-xs"
                                    to="/Development"
                                  >
                                    Learn more
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-39c82f6"
                      data-id="39c82f6"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-591673d elementor-cta--skin-cover elementor-cta--valign-middle e-transform elementor-widget elementor-widget-call-to-action"
                          data-id="591673d"
                          data-element_type="widget"
                          data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1999999999999999555910790149937383830547332763671875,"sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                          data-widget_type="call-to-action.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-cta">
                              <div class="elementor-cta__bg-wrapper">
                                <div
                                  class="elementor-cta__bg elementor-bg"
                                  // style="background-image: url()"
                                ></div>
                                <div class="elementor-cta__bg-overlay"></div>
                              </div>
                              <div class="elementor-cta__content">
                                <div class="elementor-content-item elementor-cta__content-item elementor-cta__image">
                                  <img
                                    decoding="async"
                                    width="512"
                                    height="512"
                                    alt=""
                                    data-sizes="(max-width: 512px) 100vw, 512px"
                                    class="attachment-full size-full wp-image-618 ls-is-cached lazyloaded"
                                    src={Fundraising}
                                    sizes="(max-width: 512px) 100vw, 512px"
                                  />
                                  {/* <noscript>
                                    <img
                                      decoding="async"
                                      width="512"
                                      height="512"
                                      src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png"
                                      class="attachment-full size-full wp-image-618"
                                      alt=""
                                      srcset="
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png         512w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-300x300.png 300w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-150x150.png 150w
                                    "
                                      sizes="(max-width: 512px) 100vw, 512px"
                                    />
                                  </noscript> */}
                                </div>

                                <div class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                                  Fundraising
                                </div>
                                <div class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                                  {/* <ul>
                                    <li>- Metaverse Development</li>
                                    <li>- Digital Assets Market</li>

                                    <li>- Web3 Applications</li>
                                    <li>- Dapps</li>
                                    <li>- Blockchain Development</li>
                                  </ul> */}
                                </div>

                                <div class="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item">
                                  <Link
                                    class="elementor-cta__button elementor-button elementor-size-xs"
                                    to="/Fundraising"
                                  >
                                    Learn more
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-55757ef elementor-section-content-middle elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="55757ef"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e3700bc animated fadeInRight"
              data-id="e3700bc"
              data-element_type="column"
              data-settings='{"animation":"fadeInRight"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-142073a elementor-widget elementor-widget-image"
                  data-id="142073a"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      decoding="async"
                      width="1280"
                      height="853"
                      alt="brunette young woman using virtual reality headset on blue"
                      data-srcset="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue.jpg 1280w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-300x200.jpg 300w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-1024x682.jpg 1024w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-768x512.jpg 768w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-1536x1024.jpg 1536w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-800x533.jpg 800w"
                      data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue.jpg"
                      data-sizes="(max-width: 1280px) 100vw, 1280px"
                      class="attachment-full size-full wp-image-82 ls-is-cached lazyloaded"
                      src="./Homepage – VirtaBox_files/brunette-young-woman-using-virtual-reality-headset-on-blue.jpg"
                      sizes="(max-width: 1280px) 100vw, 1280px"
                      srcset="
                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue.jpg           1280w,
                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-300x200.jpg    300w,
                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-1024x682.jpg  1024w,
                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-768x512.jpg    768w,
                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-1536x1024.jpg 1536w,
                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-800x533.jpg    800w
                    "
                    />
                    <noscript>
                      <img
                        decoding="async"
                        width="1280"
                        height="853"
                        src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue.jpg"
                        class="attachment-full size-full wp-image-82"
                        alt="brunette young woman using virtual reality headset on blue"
                        srcset="
                        https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue.jpg           1280w,
                        https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-300x200.jpg    300w,
                        https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-1024x682.jpg  1024w,
                        https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-768x512.jpg    768w,
                        https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-1536x1024.jpg 1536w,
                        https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/brunette-young-woman-using-virtual-reality-headset-on-blue-800x533.jpg    800w
                      "
                        sizes="(max-width: 1280px) 100vw, 1280px"
                      />
                    </noscript>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-01fff3e elementor-cta--valign-middle elementor-widget__width-initial elementor-absolute elementor-cta--skin-classic elementor-animated-content elementor-widget elementor-widget-call-to-action animated bounceIn"
                  data-id="01fff3e"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute","_animation":"bounceIn"}'
                  data-widget_type="call-to-action.default"
                >
                  <div class="elementor-widget-container">
                    <link
                      rel="stylesheet"
                      href="./Homepage – VirtaBox_files/widget-call-to-action.min.css"
                    />
                    <div class="elementor-cta">
                      {/* <div class="elementor-cta__content">
                        <div class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                          10 Startups
                        </div>
                        <div class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                          From August 2023
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-59834b7 animated fadeInLeft"
              data-id="59834b7"
              data-element_type="column"
              data-settings='{"animation":"fadeInLeft"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                {/* <div
                  class="elementor-element elementor-element-2fee884 elementor-widget elementor-widget-heading"
                  data-id="2fee884"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h6 class="elementor-heading-title elementor-size-default">
                      Who we are
                    </h6>
                  </div>
                </div> */}
                <div
                  class="elementor-element elementor-element-9f51add elementor-widget elementor-widget-heading"
                  data-id="9f51add"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      COHORT Z
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-23509d2 elementor-widget elementor-widget-text-editor"
                  data-id="23509d2"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    {/* <style>
                    .elementor-widget-text-editor.elementor-drop-cap-view-stacked
                      .elementor-drop-cap {
                      background-color: #69727d;
                      color: #fff;
                    }
                    .elementor-widget-text-editor.elementor-drop-cap-view-framed
                      .elementor-drop-cap {
                      color: #69727d;
                      border: 3px solid;
                      background-color: transparent;
                    }
                    .elementor-widget-text-editor:not(
                        .elementor-drop-cap-view-default
                      )
                      .elementor-drop-cap {
                      margin-top: 8px;
                    }
                    .elementor-widget-text-editor:not(
                        .elementor-drop-cap-view-default
                      )
                      .elementor-drop-cap-letter {
                      width: 1em;
                      height: 1em;
                    }
                    .elementor-widget-text-editor .elementor-drop-cap {
                      float: left;
                      text-align: center;
                      line-height: 1;
                      font-size: 50px;
                    }
                    .elementor-widget-text-editor .elementor-drop-cap-letter {
                      display: inline-block;
                    }
                  </style> */}
                    <p>
                      At Solus Labs, we have created a co-evolutionary
                      fellowship program that supports 10 founders and their
                      startups to explore a journey of possibilities in the web3
                      world.
                    </p>
                    {/* <p>Starting August 2023</p> */}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-2441f73 elementor-widget elementor-widget-button"
                  data-id="2441f73"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      {/* <Link
                        to="#"
                        target="_blank"
                        class="elementor-button-link elementor-button elementor-size-md"
                        role="button"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-text">Apply Now</span>
                        </span>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-inner-section elementor-element elementor-element-79078cb elementor-section-boxed elementor-section-height-default elementor-section-height-default teamheadings"
          data-id="79078cb"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-befa751 animated fadeInUp"
              data-id="befa751"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-8768995 elementor-widget elementor-widget-heading"
                  data-id="8768995"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    {/* <h6 class="elementor-heading-title elementor-size-default">
                      Meet our team
                    </h6>{" "} */}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-c47412f elementor-widget elementor-widget-heading"
                  data-id="c47412f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      PROJECTS WE SUPPORT
                    </h2>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e596fec elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeIn"
          data-id="e596fec"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn","animation_delay":200}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-04ee3bf"
              data-id="04ee3bf"
              data-element_type="column"
              data-settings='{"background_background":"classic"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-fdf4483 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fdf4483"
                  data-element_type="section"
                ></section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-f85cc8f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="f85cc8f"
                  data-element_type="section"
                >
                  <div class="elementor-container">
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-085620d"
                      data-id="085620d"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-8809edb elementor-cta--skin-cover elementor-cta--valign-middle e-transform elementor-widget elementor-widget-call-to-action"
                          data-id="8809edb"
                          data-element_type="widget"
                          data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1999999999999999555910790149937383830547332763671875,"sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                          data-widget_type="call-to-action.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-cta">
                              <div class="elementor-cta__bg-wrapper">
                                <div
                                  class="elementor-cta__bg elementor-bg"
                                  // style="background-image: url()"
                                ></div>
                                <div class="elementor-cta__bg-overlay"></div>
                              </div>
                              <div class="elementor-cta__content">
                                <div class="elementor-content-item elementor-cta__content-item elementor-cta__image">
                                  <img
                                    decoding="async"
                                    width="512"
                                    height="512"
                                    alt=""
                                    data-sizes="(max-width: 512px) 100vw, 512px"
                                    class="attachment-full size-full wp-image-619 ls-is-cached lazyloaded"
                                    src={gaming}
                                    sizes="(max-width: 512px) 100vw, 512px"
                                  />
                                  {/* <noscript>
                                    <img
                                      decoding="async"
                                      width="512"
                                      height="512"
                                      src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-14img_.png"
                                      class="attachment-full size-full wp-image-619"
                                      alt=""
                                      srcset="
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-14img_.png         512w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-14img_-300x300.png 300w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-14img_-150x150.png 150w
                                    "
                                      sizes="(max-width: 512px) 100vw, 512px"
                                    />
                                  </noscript> */}
                                </div>

                                <div class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                                  Gaming
                                </div>
                                <div class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                                  {/* <ul>
                                    <li>- Fellowship Program</li>
                                    <li>- Mentoring/Training</li>

                                    <li>- Growth Hacking</li>
                                    <li>- PR/Networking</li>
                                    <li>- Global Exposure</li>
                                  </ul> */}
                                </div>

                                {/* <div class="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item">
                                  <a
                                    class="elementor-cta__button elementor-button elementor-size-xs"
                                    href="https://web.moxcreative.com/virtabox/template-kit/homepage/#"
                                  >
                                    Learn more
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-39c82f6"
                      data-id="39c82f6"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-591673d elementor-cta--skin-cover elementor-cta--valign-middle e-transform elementor-widget elementor-widget-call-to-action"
                          data-id="591673d"
                          data-element_type="widget"
                          data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1999999999999999555910790149937383830547332763671875,"sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                          data-widget_type="call-to-action.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-cta">
                              <div class="elementor-cta__bg-wrapper">
                                <div
                                  class="elementor-cta__bg elementor-bg"
                                  // style="background-image: url()"
                                ></div>
                                <div class="elementor-cta__bg-overlay"></div>
                              </div>
                              <div class="elementor-cta__content">
                                <div class="elementor-content-item elementor-cta__content-item elementor-cta__image">
                                  <img
                                    decoding="async"
                                    width="512"
                                    height="512"
                                    alt=""
                                    data-sizes="(max-width: 512px) 100vw, 512px"
                                    class="attachment-full size-full wp-image-618 ls-is-cached lazyloaded"
                                    src={metaverses}
                                    sizes="(max-width: 512px) 100vw, 512px"
                                  />
                                  {/* <noscript>
                                    <img
                                      decoding="async"
                                      width="512"
                                      height="512"
                                      src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png"
                                      class="attachment-full size-full wp-image-618"
                                      alt=""
                                      srcset="
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png         512w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-300x300.png 300w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-150x150.png 150w
                                    "
                                      sizes="(max-width: 512px) 100vw, 512px"
                                    />
                                  </noscript> */}
                                </div>

                                <div class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                                  Metaverses
                                </div>
                                <div class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                                  {/* <ul>
                                    <li>- Metaverse Development</li>
                                    <li>- Digital Assets Market</li>

                                    <li>- Web3 Applications</li>
                                    <li>- Dapps</li>
                                    <li>- Blockchain Development</li>
                                  </ul> */}
                                </div>

                                {/* <div class="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item">
                                  <a
                                    class="elementor-cta__button elementor-button elementor-size-xs"
                                    href="https://web.moxcreative.com/virtabox/template-kit/homepage/#"
                                  >
                                    Learn more
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-39c82f6"
                      data-id="39c82f6"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-591673d elementor-cta--skin-cover elementor-cta--valign-middle e-transform elementor-widget elementor-widget-call-to-action"
                          data-id="591673d"
                          data-element_type="widget"
                          data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1999999999999999555910790149937383830547332763671875,"sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                          data-widget_type="call-to-action.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-cta">
                              <div class="elementor-cta__bg-wrapper">
                                <div
                                  class="elementor-cta__bg elementor-bg"
                                  // style="background-image: url()"
                                ></div>
                                <div class="elementor-cta__bg-overlay"></div>
                              </div>
                              <div class="elementor-cta__content">
                                <div class="elementor-content-item elementor-cta__content-item elementor-cta__image">
                                  <img
                                    decoding="async"
                                    width="512"
                                    height="512"
                                    alt=""
                                    data-sizes="(max-width: 512px) 100vw, 512px"
                                    class="attachment-full size-full wp-image-618 ls-is-cached lazyloaded"
                                    src={Ai}
                                    sizes="(max-width: 512px) 100vw, 512px"
                                  />
                                  {/* <noscript>
                                    <img
                                      decoding="async"
                                      width="512"
                                      height="512"
                                      src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png"
                                      class="attachment-full size-full wp-image-618"
                                      alt=""
                                      srcset="
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_.png         512w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-300x300.png 300w,
                                      https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/Asset-13img_-150x150.png 150w
                                    "
                                      sizes="(max-width: 512px) 100vw, 512px"
                                    />
                                  </noscript> */}
                                </div>

                                <div class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                                  Web3 AI & AR
                                </div>
                                <div class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                                  {/* <ul>
                                    <li>- Metaverse Development</li>
                                    <li>- Digital Assets Market</li>

                                    <li>- Web3 Applications</li>
                                    <li>- Dapps</li>
                                    <li>- Blockchain Development</li>
                                  </ul> */}
                                </div>

                                {/* <div class="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item">
                                  <a
                                    class="elementor-cta__button elementor-button elementor-size-xs"
                                    href="https://web.moxcreative.com/virtabox/template-kit/homepage/#"
                                  >
                                    Learn more
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {/* <Teams /> */}

        {/* <section
          class="elementor-section elementor-top-section elementor-element elementor-element-2215073 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="2215073"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-80b617a"
              data-id="80b617a"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-79078cb elementor-section-boxed elementor-section-height-default elementor-section-height-default teamheadings"
                  data-id="79078cb"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-befa751 animated fadeInUp"
                      data-id="befa751"
                      data-element_type="column"
                      data-settings='{"animation":"fadeInUp"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-8768995 elementor-widget elementor-widget-heading"
                          data-id="8768995"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h6 class="elementor-heading-title elementor-size-default">
                              Meet our team
                            </h6>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-c47412f elementor-widget elementor-widget-heading"
                          data-id="c47412f"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Awesome people behind us.
                            </h2>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-8e57385 elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeIn"
                  data-id="8e57385"
                  data-element_type="section"
                  data-settings='{"animation":"fadeIn","animation_delay":200}'
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-9c449b2"
                      data-id="9c449b2"
                      data-element_type="column"
                      data-settings='{"background_background":"gradient"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-d919e79 elementor-widget elementor-widget-image"
                          data-id="d919e79"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              decoding="async"
                              width="400"
                              height="400"
                              src="assets/soluslabsicon6.png"
                              class="attachment-full size-full wp-image-289"
                              alt=""
                              srcset="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0006_Layer-2.jpg 400w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0006_Layer-2-300x300.jpg 300w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0006_Layer-2-150x150.jpg 150w"
                              sizes="(max-width: 400px) 100vw, 400px"
                            />
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-7984967 elementor-widget__width-auto elementor-absolute e-transform elementor-widget elementor-widget-heading"
                          data-id="7984967"
                          data-element_type="widget"
                          data-settings='{"_position":"absolute","_transform_rotateZ_effect":{"unit":"px","size":90,"sizes":[]},"_transform_rotateZ_effect_tablet":{"unit":"deg","size":"","sizes":[]},"_transform_rotateZ_effect_mobile":{"unit":"deg","size":"","sizes":[]}}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-heading-title elementor-size-default">
                              Founder
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-3948593 elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="3948593"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-box-wrapper">
                              <div class="elementor-icon-box-content">
                                <div class="elementor-icon-box-title">
                                  <span>Samuel Graham </span>
                                </div>
                                <p class="elementor-icon-box-description">
                                  Et non ligula turpis leo curabitur metus
                                  malesuada rutrum eros{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-6a085c5 elementor-shape-square elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                          data-id="6a085c5"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-social-icons-wrapper elementor-grid socialsicon">
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-e79e72c "
                                  target="_blank"
                                >

                                  <AiOutlineFacebook />
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-0790f70"
                                  target="_blank"
                                >
                                  <AiOutlineLinkedin />
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-ffcafe3"
                                  target="_blank"
                                >
                                  <AiOutlineYoutube />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-931a5b8"
                      data-id="931a5b8"
                      data-element_type="column"
                      data-settings='{"background_background":"gradient"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-f0756a1 elementor-widget elementor-widget-image"
                          data-id="f0756a1"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              decoding="async"
                              width="400"
                              height="400"
                              alt=""
                              data-srcset="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0001_Layer-7.jpg 400w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0001_Layer-7-300x300.jpg 300w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0001_Layer-7-150x150.jpg 150w"
                              data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0001_Layer-7.jpg"
                              data-sizes="(max-width: 400px) 100vw, 400px"
                              class="attachment-full size-full wp-image-284 ls-is-cached lazyloaded"
                              src="assets/soluslablogo.png"
                              sizes="(max-width: 400px) 100vw, 400px"
                              srcset="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0001_Layer-7.jpg 400w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0001_Layer-7-300x300.jpg 300w, https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0001_Layer-7-150x150.jpg 150w"
                            />
                          </div>
                       
                        </div>
                        <div
                          class="elementor-element elementor-element-e9468d1 elementor-widget__width-auto elementor-absolute e-transform elementor-widget elementor-widget-heading"
                          data-id="e9468d1"
                          data-element_type="widget"
                          data-settings='{"_position":"absolute","_transform_rotateZ_effect":{"unit":"px","size":90,"sizes":[]},"_transform_rotateZ_effect_tablet":{"unit":"deg","size":"","sizes":[]},"_transform_rotateZ_effect_mobile":{"unit":"deg","size":"","sizes":[]}}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-heading-title elementor-size-default">
                              Co-Founder
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-8e1d4f2 elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="8e1d4f2"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-box-wrapper">
                              <div class="elementor-icon-box-content">
                                <div class="elementor-icon-box-title">
                                  <span>Frederick Gibson </span>
                                </div>
                                <p class="elementor-icon-box-description">
                                  Et non ligula turpis leo curabitur metus
                                  malesuada rutrum eros{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-02d5dcc elementor-shape-square elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                          data-id="02d5dcc"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-social-icons-wrapper elementor-grid socialsicon">
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-e79e72c "
                                  target="_blank"
                                >

                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-0790f70"
                                  target="_blank"
                                >
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-ffcafe3"
                                  target="_blank"
                                >
                                  <AiOutlineYoutube />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4966078"
                      data-id="4966078"
                      data-element_type="column"
                      data-settings='{"background_background":"gradient"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-b0c5a3f elementor-widget elementor-widget-image"
                          data-id="b0c5a3f"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                         
                            <img
                              title="logo_virtabox"
                              alt="logo_virtabox"
                              loading="lazy"
                              data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                              class="attachment-full size-full wp-image-289"
                              src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0006_Layer-2.jpg"
                            />
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-3099839 elementor-widget__width-auto elementor-absolute e-transform elementor-widget elementor-widget-heading"
                          data-id="3099839"
                          data-element_type="widget"
                          data-settings='{"_position":"absolute","_transform_rotateZ_effect":{"unit":"px","size":90,"sizes":[]},"_transform_rotateZ_effect_tablet":{"unit":"deg","size":"","sizes":[]},"_transform_rotateZ_effect_mobile":{"unit":"deg","size":"","sizes":[]}}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-heading-title elementor-size-default">
                              Business Manager
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-7c7c47f elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="7c7c47f"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-box-wrapper">
                              <div class="elementor-icon-box-content">
                                <div class="elementor-icon-box-title">
                                  <span>Harvey Barrett </span>
                                </div>
                                <p class="elementor-icon-box-description">
                                  Et non ligula turpis leo curabitur metus
                                  malesuada rutrum eros{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-9dba97a elementor-shape-square elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                          data-id="9dba97a"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-social-icons-wrapper elementor-grid socialsicon">
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-e79e72c "
                                  target="_blank"
                                >

                                  <AiOutlineFacebook />
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-0790f70"
                                  target="_blank"
                                >
                                  <AiOutlineLinkedin />
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-ffcafe3"
                                  target="_blank"
                                >
                                  <AiOutlineYoutube />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-015187c"
                      data-id="015187c"
                      data-element_type="column"
                      data-settings='{"background_background":"gradient"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-5bf0b98 elementor-widget elementor-widget-image"
                          data-id="5bf0b98"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                          
                            <img
                              title="logo_virtabox"
                              alt="logo_virtabox"
                              loading="lazy"
                              data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                              class="attachment-full size-full wp-image-289"
                              src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/2023/01/img__0006_Layer-2.jpg"
                            />
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-08a806a elementor-widget__width-auto elementor-absolute e-transform elementor-widget elementor-widget-heading"
                          data-id="08a806a"
                          data-element_type="widget"
                          data-settings='{"_position":"absolute","_transform_rotateZ_effect":{"unit":"px","size":90,"sizes":[]},"_transform_rotateZ_effect_tablet":{"unit":"deg","size":"","sizes":[]},"_transform_rotateZ_effect_mobile":{"unit":"deg","size":"","sizes":[]}}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-heading-title elementor-size-default">
                              Marketing Manager
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-e93e057 elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="e93e057"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-box-wrapper">
                              <div class="elementor-icon-box-content">
                                <div class="elementor-icon-box-title">
                                  <span>Gabriella Howell </span>
                                </div>
                                <p class="elementor-icon-box-description">
                                  Et non ligula turpis leo curabitur metus
                                  malesuada rutrum eros{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-3dd67db elementor-shape-square elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                          data-id="3dd67db"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-social-icons-wrapper elementor-grid socialsicon">
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-e79e72c "
                                  target="_blank"
                                >

                                  <AiOutlineFacebook />
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-0790f70"
                                  target="_blank"
                                >
                                  <AiOutlineLinkedin />
                                </a>
                              </span>
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-ffcafe3"
                                  target="_blank"
                                >
                                  <AiOutlineYoutube />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          class="elementor-section elementor-top-section elementor-element elementor-element-8a8a99a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="8a8a99a"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-577c8e5 animated fadeInUp"
              data-id="577c8e5"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-a318e2f elementor-widget elementor-widget-heading"
                  data-id="a318e2f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-heading-title elementor-size-default">
                      Join the virtual reality revolution and upgrade your
                      entertainment.
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-4995857 elementor-widget elementor-widget-text-editor"
                  data-id="4995857"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-9b13dee elementor-align-center elementor-widget elementor-widget-button"
                  data-id="9b13dee"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <div class="formcontainer">
                        <motion.input
                          variants={children}
                          type="text"
                          name="user_name"
                          placeholder="    Full Name"
                        />
                    

                        <motion.input
                          variants={children}
                          type="email"
                          name="user_email"
                          placeholder="   Email Adress"
                        />
                        <a
                          href="https://web.moxcreative.com/virtabox/template-kit/homepage/#"
                          class="elementor-button-link elementor-button elementor-size-md"
                          role="button"
                        >
                          <span class="elementor-button-content-wrapper">
                            <span class="elementor-button-text">Subscribe</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          class="elementor-section elementor-inner-section elementor-element elementor-element-69616ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="69616ea"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cd6eece"
              data-id="cd6eece"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-3f267bb elementor-widget elementor-widget-image"
                  data-id="3f267bb"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      title="logo_virtabox"
                      alt="logo_virtabox"
                      loading="lazy"
                      data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                      class="ls-is-cached lazyloaded footerlogo"
                  
                      src="assets/soluslabsicon6.png"
                    />
                    <noscript>
                      <img
                        src="assets/soluslablogo.png"
                        title="logo_virtabox"
                        alt="logo_virtabox"
                        loading="lazy"
                      />
                    </noscript>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5fe5f3f"
              data-id="5fe5f3f"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-01d7379 elementor-icon-list--layout-inline elementor-align-right elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                  data-id="01d7379"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div class="elementor-widget-container">
                    <link
                      rel="stylesheet"
                      href="./Homepage – VirtaBox_files/widget-icon-list.min.css"
                    />
                    <ul class="elementor-icon-list-items elementor-inline-items">
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <a href="https://web.moxcreative.com/virtabox/template-kit/homepage/#">
                          <span class="elementor-icon-list-text">About us</span>
                        </a>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <a href="https://web.moxcreative.com/virtabox/template-kit/homepage/#">
                          <span class="elementor-icon-list-text">
                            Contact us
                          </span>
                        </a>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <a href="https://web.moxcreative.com/virtabox/template-kit/homepage/#">
                          <span class="elementor-icon-list-text">
                            Term of use
                          </span>
                        </a>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <a href="https://web.moxcreative.com/virtabox/template-kit/homepage/#">
                          <span class="elementor-icon-list-text">
                            Privacy Policy
                          </span>
                        </a>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <a href="https://web.moxcreative.com/virtabox/template-kit/homepage/#">
                          <span class="elementor-icon-list-text">
                            Cookie Policy
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-inner-section elementor-element elementor-element-318e9e4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="318e9e4"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-033e570"
              data-id="033e570"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-2c656dd elementor-shape-square e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons"
                  data-id="2c656dd"
                  data-element_type="widget"
                  data-widget_type="social-icons.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-social-icons-wrapper elementor-grid">
                      <span class="elementor-grid-item">
                        <a
                          class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-60b958b"
                          target="_blank"
                        >
                          <GrFacebookOption />
                        </a>
                      </span>
                      <span class="elementor-grid-item">
                        <a
                          class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-pop elementor-repeater-item-f4ed697"
                          target="_blank"
                        >
                          <AiOutlineInstagram />
                        </a>
                      </span>
                      <span class="elementor-grid-item">
                        <a
                          class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-88f8c00"
                          target="_blank"
                        >
                          <AiOutlineLinkedin />
                        </a>
                      </span>
                      <span class="elementor-grid-item footericon">
                        <a
                          class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-b77bf84"
                          target="_blank"
                        >
                          <RiYoutubeFill />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a511ce2 footertext"
              data-id="a511ce2"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-fb49ffc elementor-widget elementor-widget-heading"
                  data-id="fb49ffc"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-heading-title elementor-size-default">
                      Copyright © 2023 VirtaBox, All rights reserved. Powered by
                      MoxCreative.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

{/* <section className="elementor-section-portfolio">
      <h2>PARTNERS & PORTFOLIO</h2>
      
       <div className="brand-slider-wrapper">
        <div className="brand-logos marquee d-none">
                <div className="logo-boxes-wrapper">
                  <img src={img40} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img41} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>
                
                <div className="logo-boxes-wrapper">
                  <img src={img42} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img43} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img44} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img45} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>
                
                <div className="logo-boxes-wrapper">
                  <img src={img46} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img47} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img48} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img50} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img51} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img52} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>
            </div>
            <div className="brand-logos marquee marquee2">
                <div className="logo-boxes-wrapper">
                  <img src={img40} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img41} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>
                
                <div className="logo-boxes-wrapper">
                  <img src={img42} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img43} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img44} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img45} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>
                
                <div className="logo-boxes-wrapper">
                  <img src={img46} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img47} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img48} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img50} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img51} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>

                <div className="logo-boxes-wrapper">
                  <img src={img52} decoding="async" alt="Frame 7" loading="lazy"/>
                </div>
            </div>
          </div>
    </section> */}




    <div className="partner-portfolio">
         <div className="text-box">
            <h3>PARTNERS</h3>
            <div className="partner-box">
              <img src={img40} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img41} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img42} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img43} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img52} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img50} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img54} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img55} decoding="async" alt="Frame 7" loading="lazy"></img>
            </div>
         </div>

         <div className="box-para-text">
         <h3>PORTFOLIO</h3>
         <div className="potfolio-box">
              <img src={img46} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img47} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img51} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img49} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img45} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img53} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img56} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img57} decoding="async" alt="Frame 7" loading="lazy"></img>
              <img src={img58} decoding="async" alt="Frame 7" loading="lazy"></img>
            </div>
         </div>

         <div className="color-overlay"></div>
      </div>

    
       


        <div
          data-elementor-type="footer"
          data-elementor-id="60"
          class="elementor elementor-60 elementor-location-footer"
        >
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-587918e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="587918e"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-background-overlay"></div>
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8b12d21"
                data-id="8b12d21"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-d9c7592 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="d9c7592"
                    data-element_type="section"
                    data-settings='{"background_background":"gradient"}'
                  >
                    <div class="elementor-background-overlay"></div>
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2a0708d"
                        data-id="2a0708d"
                        data-element_type="column"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-47f39e0 elementor-widget elementor-widget-heading"
                            data-id="47f39e0"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-heading-title elementor-size-default">
                                SUBSCRIBE TO GET UPDATES
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-359ff19 elementor-widget elementor-widget-text-editor"
                            data-id="359ff19"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div class="elementor-widget-container">
                              <p>
                                "Learn about exclusive updates and latest happenings in SolusLabs Ecosystem" 
                              </p>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-d8aaf94 elementor-align-center elementor-widget elementor-widget-button"
                            data-id="d8aaf94"
                            data-element_type="widget"
                            data-widget_type="button.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-button-wrapper">
                                <div class="formcontainer">
                                  <input
                                    class="inputfields"
                                    variants={children}
                                    type="text"
                                    name="user_name"
                                    placeholder="    Full Name"
                                  />

                                  <input
                                    class="inputfields"
                                    variants={children}
                                    type="email"
                                    name="user_email"
                                    placeholder="   Email Adress"
                                  />
                                  <a
                                    href="Becomeapartner"
                                    class="elementor-button-link elementor-button elementor-size-md"
                                    role="button"
                                  >
                                    <span class="elementor-button-content-wrapper">
                                      <span class="elementor-button-text">
                                        Subscribe
                                      </span>
                                    </span>
                                  </a>
                                </div>
                                {/* <a
                                  href="/Contact"
                                  class="elementor-button-link elementor-button elementor-size-lg"
                                  role="button"
                                >
                                  <span class="elementor-button-content-wrapper">
                                    <span class="elementor-button-text">
                                      Get Started
                                    </span>
                                  </span>
                                </a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-69616ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="69616ea"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cd6eece"
                        data-id="cd6eece"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-3f267bb elementor-widget elementor-widget-image"
                            data-id="3f267bb"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div class="elementor-widget-container">
                              <img
                                title="logo_virtabox"
                                alt="logo_virtabox"
                                loading="lazy"
                                data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                                class="ls-is-cached lazyloaded footerlogo"
                                src="assets/soluslabsicon7.png"
                              />
                              <noscript>
                                <img
                                  src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                                  title="logo_virtabox"
                                  alt="logo_virtabox"
                                  loading="lazy"
                                />
                              </noscript>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5fe5f3f"
                        data-id="5fe5f3f"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-01d7379 elementor-icon-list--layout-inline elementor-align-right elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                            data-id="01d7379"
                            data-element_type="widget"
                            data-widget_type="icon-list.default"
                          >
                            <div class="elementor-widget-container">
                              <link rel="stylesheet" href="/" />
                              <ul class="elementor-icon-list-items elementor-inline-items">
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/">
                                    <span class="elementor-icon-list-text">
                                      Home
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Incubation">
                                    <span class="elementor-icon-list-text">
                                      Incubation
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Development">
                                    <span class="elementor-icon-list-text">
                                      Development
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Fundraising">
                                    <span class="elementor-icon-list-text">
                                      Fundraising
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link
                                    to="/Becomeapartner"
                                    target="_blank"
                                  >
                                    <span class="elementor-icon-list-text">
                                      Become A Partner
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-318e9e4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default "
                    data-id="318e9e4"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default ">
                      {/* <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-033e570 "
                        data-id="033e570"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-2c656dd elementor-shape-square e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons iconsflex"
                            data-id="2c656dd"
                            data-element_type="widget"
                            data-widget_type="social-icons.default"
                          >
                            <div class="elementor-widget-container ">
                              <div class="  iconsflex">
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-60b958b"
                                    target="_blank"
                                  >
                                    <RiFacebookFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-pop elementor-repeater-item-f4ed697"
                                    target="_blank"
                                  >
                                    <RiInstagramFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-88f8c00"
                                    target="_blank"
                                  >
                                    <RiLinkedinBoxFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-b77bf84"
                                    target="_blank"
                                  >
                                    <RiYoutubeFill />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a511ce2"
                        data-id="a511ce2"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-fb49ffc elementor-widget elementor-widget-heading"
                            data-id="fb49ffc"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-heading-title elementor-size-default">
                                Copyright © 2023 SolusLabs, All rights reserved.
                                Powered by SolusLabs.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Mainhome;
