import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import useScrollAnimation from "../customHooks/useScrollAnimation";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { parent, children } from "../animations/animations";
// import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
// import logo from "../../../assets/Logo.png";
import "../../css/animations.min.css";
import "../../css/brands.min.css";
import "../../css/classic-themes.min.css";
import img1 from "../../../src/assets/trustvibeslogo.png";
import img2 from "../../../src/assets/personifilogo2.png";
// import Developmentmain from "../../../src/assets/Developmentmain.jpg";
import Fundraisings from "../../../src/assets/fundrising.png";
import icon1 from "../../../src/assets/Legal.png";
import icon2 from "../../../src/assets/Project.png";
import icon3 from "../../../src/assets/VC.png";
import icon4 from "../../../src/assets/SEED.png";

import logo from "../../../src/assets/soluslablogo.png";
import { GrFacebookOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";

import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";

import { RiYoutubeFill } from "react-icons/ri";
import { RiFacebookFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";

import { RiLinkedinBoxFill } from "react-icons/ri";

import { AiOutlineLinkedin } from "react-icons/ai";
// import videobg from "../../../src/animvid.webm";

// import "../../css/devicons.min.css";

import "../../css/frontend-lite.min(1).css";
import "../../css/frontend-lite.min.css";
import "../../css/post-7.css";
import "../../css/post-44.css";
import "../../css/post-60.css";

import "../../css/post-7288.css";
import "../../css/post-728.css";

import "../../css/post-1051.css";

import "../../css/responsive.css";

// import "../../css/simple-line-icons.css";

import "../../css/solid.min.css";

import "../../css/style.min.css";

import "../../css/style.min(1).css";

import "../../css/swiper.min.css";

import "../../css/template-kit-export-public.min.css";

import "../../css/theme.min.css";

import "../../css/themify.css";

import "../../css/widget-call-to-action.min.css";

import "../../css/widget-carousel.min.css";

import "../../css/widget-icon-box.min.css";

import "../../css/widget-icon-list.min.css";

import "../../css/widget-icon-list.min.css";

import "../../css/widget-styles.css";
import "../../css/widget-nav-menu.min.css";
import "../../css/post-882.css";
import classes from "../../css/Navbar.module.css";

const Fundraising = ({ classname, logo }) => {
  const [show, setShow] = useState(false);
  const Links = Scroll.Link;
  const [ref, animation] = useScrollAnimation();
  return (
    <>
      <div
        data-elementor-type="header"
        data-elementor-id="44"
        class="elementor elementor-44 elementor-location-header "
      >
        <section
          class=" elementor-section elementor-top-section elementor-element elementor-element-a13ea4d elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a13ea4d"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-204272a"
              data-id="204272a"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-5b04703 elementor-section-content-middle elementor-reverse-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default "
                  data-id="5b04703"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac59a29"
                      data-id="ac59a29"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-200cc96 elementor-widget elementor-widget-image"
                          data-id="200cc96"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <a href="/">
                              <img
                                title="logo_virtabox"
                                alt="logo_virtabox"
                                loading="lazy"
                                class="ls-is-cached lazyloaded"
                                src="assets/soluslabsicon7.png"
                              />
                              <noscript>
                                <img
                                  src={logo}
                                  title="logo_virtabox"
                                  alt="logo_virtabox"
                                  loading="lazy"
                                />
                              </noscript>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cd7c8ca"
                      data-id="cd7c8ca"
                      data-element_type="column"
                    >
                      <link
                        rel="stylesheet"
                        href="../../css/widget-nav-menu.min.css"
                      />

                      <button
                        className={
                          show
                            ? `${classes.menuBtn} ${classes.mobileMenuBtn}`
                            : classes.menuBtn
                        }
                        onClick={() => setShow(!show)}
                      >
                        {show ? <IoCloseSharp /> : <HiMenuAlt3 />}
                      </button>

                      <motion.nav
                        className={` ${classes[classname]} ${classes.navbar}`}
                        ref={ref}
                        variants={parent}
                        initial="hidden"
                        animate={animation}
                      >
                        {/* <div className={classes.logo}>
        <img src="assets/logo.png" alt="logo" />
      </div> */}

                        <motion.ul
                          ref={ref}
                          variants={parent}
                          initial="hidden"
                          animate={animation}
                          className={
                            show
                              ? `${classes.navLinks} ${classes.mobileMenu}`
                              : classes.navLinks
                          }
                        >
                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/">
            <a onClick={() => setShow(false)}>Home</a>
          </Link>
        </motion.li> */}

                          {/* <a onClick={() => setShow(false)}>EMC</a> */}
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/">Home</Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Hackfest" spy={true}>
                              {/* <a onClick={() => setShow(false)}>Services</a> */}
                              Hackfest
                            </Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Incubation" spy={true}>
                              {/* <a onClick={() => setShow(false)}>Services</a> */}
                              Incubation
                            </Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Development">
                              {/* <a onClick={() => setShow(false)}>
              Products
            </a> */}
                              Development
                            </Link>
                          </motion.li>
                          <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Fundraising">
                              {/* <a onClick={() => setShow(false)}>Portfolio</a> */}
                              Fundraising
                            </Link>
                          </motion.li>
                          <motion.li
                            // className={classes.link}
                            className={
                              show ? ` ${classes.link} ` : classes.hidee
                            }
                            variants={parent}
                          >
                            <Link to={"/Becomeapartner"} target="_blank">
                              Become A Partner
                            </Link>
                          </motion.li>
                          {/* <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/Trainings">
                   
                              Trainings
                            </Link>
                          </motion.li> */}
                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/">
          <a onClick={() => setShow(false)}>
            NFTs <ToolTip />
          </a>
          </Link>
        </motion.li> */}

                          {/* <motion.li className={classes.link} variants={children}>
          <Link to="/NewsLetter">
            <a onClick={() => setShow(false)}>NewsLetter</a>
          </Link>
        </motion.li> */}
                          {/* <motion.li
                            className={classes.link}
                            variants={children}
                          >
                            <Link to="/contact">
                              Get in Touch
                            </Link>
                          </motion.li> */}
                        </motion.ul>
                        {/* <button
                                className={
                                  show
                                    ? `${classes.menuBtn} ${classes.mobileMenuBtn}`
                                    : classes.menuBtn
                                }
                                onClick={() => setShow(!show)}
                              >
                                {show ? <IoCloseSharp /> : <HiMenuAlt3 />}
                              </button> */}
                      </motion.nav>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-918ac93 elementor-hidden-mobile"
              data-id="918ac93"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7684856 elementor-align-right elementor-widget elementor-widget-button"
                  data-id="7684856"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <div
                        class="elementor-button-link elementor-button elementor-size-md"
                        role="button"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-text">
                            <Link to={"/Becomeapartner"} target="_blank">
                              {" "}
                              Become A Partner
                            </Link>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="882"
        class="elementor elementor-882"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-82e12fe elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="82e12fe"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bd82472"
              data-id="bd82472"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-76c0d29 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="76c0d29"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-75001b7"
                      data-id="75001b7"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-43719c0 elementor-widget elementor-widget-image"
                          data-id="43719c0"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              class="imgicon"
                              decoding="async"
                              title="Asset 7img_"
                              alt="Asset 7img_"
                              loading="lazy"
                              src={Fundraisings}
                            />
                            {/* <img decoding="async" title="Asset 7img_" alt="Asset 7img_" loading="lazy" data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/Asset-7img_-q14nsmv0apg75h35netbkn4es9cdg155wfa17ephq8.png" class=" lazyloaded" src="./Single service – VirtaBox_files/Asset-7img_-q14nsmv0apg75h35netbkn4es9cdg155wfa17ephq8.png"><noscript><img decoding="async" src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/Asset-7img_-q14nsmv0apg75h35netbkn4es9cdg155wfa17ephq8.png" title="Asset 7img_" alt="Asset 7img_" loading="lazy" /></noscript>															</div> */}
                          </div>
                        </div>

                        <div
                          class="elementor-element elementor-element-a6a6814 elementor-widget elementor-widget-heading animated fadeInUp"
                          data-id="a6a6814"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">
                              Fundraising
                            </h1>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-a26bb2e elementor-widget elementor-widget-heading animated fadeInUp"
                          data-id="a26bb2e"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp","_animation_delay":200}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              Solus Labs enables emerging selected web3 founders
                              to leverage decentralized fundraising strategies.
                            </h4>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-95cf0e5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="95cf0e5"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            {/* <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-965c655 animated fadeInLeft"
              data-id="965c655"
              data-element_type="column"
              data-settings='{"animation":"fadeInLeft"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-744f614 elementor-widget elementor-widget-heading"
                  data-id="744f614"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h6 class="elementor-heading-title elementor-size-default">
                      Our Services
                    </h6>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-de916d1 elementor-widget elementor-widget-heading"
                  data-id="de916d1"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      From your imagination to an entire universe, experience
                      the magic of VR.
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-16eaf43 elementor-widget elementor-widget-text-editor"
                  data-id="16eaf43"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Pede arcu neque ligula nulla tempor cras vestibulum.
                      Suspendisse condimentum suscipit massa id mi fermentum
                      interdum velit pharetra quis lacus. Sodales placerat
                      venenatis nibh non proin mi.
                    </p>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-c2f314c elementor-widget elementor-widget-button"
                  data-id="c2f314c"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <a
                        href="/"
                        class="elementor-button-link elementor-button elementor-size-md"
                        role="button"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-text">
                            Discover more
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fbeacb7"
              data-id="fbeacb7"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="flex1">
                  <div
                    class="elementor-element elementor-element-65f7e0c elementor-view-stacked elementor-shape-square elementor-position-left elementor-tablet-position-top elementor-mobile-position-left elementor-widget-tablet__width-initial animated-fast elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInRight"
                    data-id="65f7e0c"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInRight"}'
                    data-widget_type="icon-box.default"
                  >
                    <div class="elementor-widget-container">
                      {/* <link rel="stylesheet" href="./Single service – VirtaBox_files/widget-icon-box.min.css"> */}
                      <div class="elementor-icon-box-wrapper">
                        <div class="elementor-icon-box-icon">
                          <span class="elementor-icon elementor-animation-">
                            <img
                              class="icons"
                              decoding="async"
                              title="Asset 7img_"
                              alt="Asset 7img_"
                              loading="lazy"
                              src={icon4}
                            />
                          </span>
                        </div>
                        <div class="elementor-icon-box-content">
                          <div class="elementor-icon-box-title">
                            <span>Seed Funding </span>
                          </div>
                          <p class="elementor-icon-box-description">
                            Our seed funding mechanism is designed to produce
                            measurable outcomes that benefit web3 startups.
                            Chosen startups must reach specific milestones to
                            qualify for the seed funding, which serves as our
                            commitment to their success.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-7d8e70a elementor-view-stacked elementor-shape-square elementor-position-left elementor-tablet-position-top elementor-mobile-position-left elementor-widget-tablet__width-initial animated-fast elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInRight"
                    data-id="7d8e70a"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInRight","_animation_delay":200}'
                    data-widget_type="icon-box.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-icon-box-wrapper">
                        <div class="elementor-icon-box-icon">
                          <span class="elementor-icon elementor-animation-">
                            <img
                              class="icons"
                              decoding="async"
                              title="Asset 7img_"
                              alt="Asset 7img_"
                              loading="lazy"
                              src={icon3}
                            />
                          </span>
                        </div>
                        <div class="elementor-icon-box-content">
                          <div class="elementor-icon-box-title">
                            <span>VC Outreach </span>
                          </div>
                          <p class="elementor-icon-box-description">
                            The Web3 ecosystem offers emerging founders the
                            chance to utilize decentralized fundraising methods
                            that are unavailable to conventional startup teams.
                            We aid startups in accomplishing their seed round
                            fundraising objectives by connecting them with our
                            roster of over 200 web3 VC firms and individuals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-8a384fa elementor-view-stacked elementor-shape-square elementor-position-left elementor-tablet-position-top elementor-mobile-position-left elementor-widget-tablet__width-initial animated-fast elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInRight"
                    data-id="8a384fa"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInRight","_animation_delay":300}'
                    data-widget_type="icon-box.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-icon-box-wrapper">
                        <div class="elementor-icon-box-icon">
                          <span class="elementor-icon elementor-animation-">
                            <img
                              class="icons"
                              decoding="async"
                              title="Asset 7img_"
                              alt="Asset 7img_"
                              loading="lazy"
                              src={icon2}
                            />
                          </span>
                        </div>
                        <div class="elementor-icon-box-content">
                          <div class="elementor-icon-box-title">
                            <span>Project Launch/Listing </span>
                          </div>
                          <p class="elementor-icon-box-description">
                            Launching a web3 startup differs significantly from
                            a traditional web2 startup launch. Our team provides
                            valuable insights and innovative strategies to help
                            startups make informed decisions regarding
                            launchpads, market makers, and influencers, among
                            other critical factors.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-8a384fa elementor-view-stacked elementor-shape-square elementor-position-left elementor-tablet-position-top elementor-mobile-position-left elementor-widget-tablet__width-initial animated-fast elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInRight"
                    data-id="8a384fa"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInRight","_animation_delay":300}'
                    data-widget_type="icon-box.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-icon-box-wrapper">
                        <div class="elementor-icon-box-icon">
                          <span class="elementor-icon elementor-animation-">
                            <img
                              class="icons"
                              decoding="async"
                              title="Asset 7img_"
                              alt="Asset 7img_"
                              loading="lazy"
                              src={icon1}
                            />
                          </span>
                        </div>
                        <div class="elementor-icon-box-content">
                          <div class="elementor-icon-box-title">
                            <span>Legal </span>
                          </div>
                          <p class="elementor-icon-box-description">
                            The Web3 industry is still in its early stages, with
                            the regulatory framework for startups to operate and
                            remain compliant continuing to evolve. Our team of
                            legal experts is equipped to help you navigate new
                            regulations and stay ahead of the game.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          data-elementor-type="footer"
          data-elementor-id="60"
          class="elementor elementor-60 elementor-location-footer"
        >
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-587918e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="587918e"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-background-overlay"></div>
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8b12d21"
                data-id="8b12d21"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-d9c7592 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="d9c7592"
                    data-element_type="section"
                    data-settings='{"background_background":"gradient"}'
                  >
                    <div class="elementor-background-overlay"></div>
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2a0708d"
                        data-id="2a0708d"
                        data-element_type="column"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-47f39e0 elementor-widget elementor-widget-heading"
                            data-id="47f39e0"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-heading-title elementor-size-default">
                              SUBSCRIBE TO GET UPDATES
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-359ff19 elementor-widget elementor-widget-text-editor"
                            data-id="359ff19"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div class="elementor-widget-container">
                              <p>"Learn about exclusive updates and latest happenings in SolusLabs Ecosystem"</p>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-d8aaf94 elementor-align-center elementor-widget elementor-widget-button"
                            data-id="d8aaf94"
                            data-element_type="widget"
                            data-widget_type="button.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-button-wrapper">
                                <div class="formcontainer">
                                  <motion.input
                                    variants={children}
                                    type="text"
                                    name="user_name"
                                    placeholder="    Full Name"
                                  />

                                  <motion.input
                                    variants={children}
                                    type="email"
                                    name="user_email"
                                    placeholder="   Email Adress"
                                  />
                                  <a
                                    href="/"
                                    class="elementor-button-link elementor-button elementor-size-md"
                                    role="button"
                                  >
                                    <span class="elementor-button-content-wrapper">
                                      <span class="elementor-button-text">
                                        Subscribe
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-69616ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="69616ea"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cd6eece"
                        data-id="cd6eece"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-3f267bb elementor-widget elementor-widget-image"
                            data-id="3f267bb"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div class="elementor-widget-container">
                              <img
                                title="logo_virtabox"
                                alt="logo_virtabox"
                                loading="lazy"
                                data-src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                                class="ls-is-cached lazyloaded footerlogo"
                                src="assets/soluslabsicon7.png"
                              />
                              <noscript>
                                <img
                                  src="https://web.moxcreative.com/virtabox/wp-content/uploads/sites/5/elementor/thumbs/logo_virtabox-q12lnrrzd7yz60ovx5kaymswm5dh8bqy221i6gkoao.png"
                                  title="logo_virtabox"
                                  alt="logo_virtabox"
                                  loading="lazy"
                                />
                              </noscript>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5fe5f3f"
                        data-id="5fe5f3f"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-01d7379 elementor-icon-list--layout-inline elementor-align-right elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                            data-id="01d7379"
                            data-element_type="widget"
                            data-widget_type="icon-list.default"
                          >
                            <div class="elementor-widget-container">
                              <link
                                rel="stylesheet"
                                href="./Homepage – VirtaBox_files/widget-icon-list.min.css"
                              />
                              <ul class="elementor-icon-list-items elementor-inline-items">
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/">
                                    <span class="elementor-icon-list-text">
                                      Home
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Incubation">
                                    <span class="elementor-icon-list-text">
                                      Incubation
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Development">
                                    <span class="elementor-icon-list-text">
                                      Development
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to="/Fundraising">
                                    <span class="elementor-icon-list-text">
                                      Fundraising
                                    </span>
                                  </Link>
                                </li>
                                <li class="elementor-icon-list-item elementor-inline-item">
                                  <Link to={"/Becomeapartner"} target="_blank">
                                    <span class="elementor-icon-list-text">
                                      Become A Partner
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-318e9e4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default "
                    data-id="318e9e4"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default ">
                      {/* <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-033e570 "
                        data-id="033e570"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-2c656dd elementor-shape-square e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons iconsflex"
                            data-id="2c656dd"
                            data-element_type="widget"
                            data-widget_type="social-icons.default"
                          >
                            <div class="elementor-widget-container ">
                              <div class="  iconsflex">
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-pop elementor-repeater-item-60b958b"
                                    target="_blank"
                                  >
                                    <RiFacebookFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-pop elementor-repeater-item-f4ed697"
                                    target="_blank"
                                  >
                                    <RiInstagramFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-pop elementor-repeater-item-88f8c00"
                                    target="_blank"
                                  >
                                    <RiLinkedinBoxFill />
                                  </a>
                                </span>
                                <span class="elementor-grid-item">
                                  <a
                                    class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-pop elementor-repeater-item-b77bf84"
                                    target="_blank"
                                  >
                                    <RiYoutubeFill />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a511ce2"
                        data-id="a511ce2"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-fb49ffc elementor-widget elementor-widget-heading"
                            data-id="fb49ffc"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-heading-title elementor-size-default">
                                Copyright © 2023 SolusLabs, All rights reserved.
                                Powered by SolusLabs.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Fundraising;
